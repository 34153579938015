const mapper = (item: any, region?: any) => {
  if (!item || !item.data) return
  let mapped = {
    term: item.term,
    isUrl: item.isUrl
  }
  if (mapped.isUrl) {
    return {
      ...mapped,
      code: item.data?.suggestions ? item.data.suggestions[0]?.code : null,
      url: item.data?.products ? item.data.products[0]?.url : null,
      name: item.data?.products ? item.data.products[0]?.name : null,
      description: item?.data.suggestions
        ? item.data.suggestions[1]?.subheading_text
        : null
    }
  } else {
    const codes = item.data?.results && item.data?.results[0]?.codes
    let code
    if (codes) {
      if (region) {
        code = codes.filter((c: any) => c.country === region)?.[0]?.code
      } else {
        code = codes.filter((c: any) => c.country === 'global')?.[0]?.code
      }
    }
    return {
      ...mapped,
      code,
      description: item.data?.results
        ? item.data.results[0].subheading_text
        : ''
    }
  }
}

const isUrl = (searchValue: string) => {
  // TODO - improve condition to check if the searchValue is a valid URL
  return searchValue.startsWith('http')
}

export { mapper, isUrl }
