import { useContext, useState } from 'react'
import styles from './InteractiveClassifier.module.scss'
import {
  TextInput,
  Button,
  InlineLoading,
  Select,
  SelectItem
} from 'carbon-components-react'
import { Close32 } from '@carbon/icons-react'
import { handleNameRequest, handleUrlRequest } from '../../lib/apiClient'
import { mapper, isUrl } from '../../lib/dataMapper'
import { getAuthData } from '../../lib/util'
import UnderlineLink from '../UnderlineLink/UnderlineLink'
import { AccountContext } from '../../App'

const InteractiveClassifier = ({ setData, user }: any) => {
  const authData = getAuthData(user)
  const { account } = useContext(AccountContext)
  const [searchValue, setSearchValue] = useState('')
  const [bulkSearchValues, setMultiSearchValues] = useState<string[]>([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const regions = [
    {
      text: 'Universal',
      value: 'global'
    },
    {
      text: 'US - United States',
      value: 'usa'
    },
    {
      text: 'EU - European Union',
      value: 'eu'
    }
  ]

  const [selectedItems, setSelectedItems] = useState<any[]>([])

  const handleChange = (event: any) => {
    const value = event.target.value
    setSearchValue(value)
  }
  const onKeyPress = (e: any) => {
    if (e.which === 13) {
      hanldeBulkRequest()
    }
  }
  const onDelete = (deletedItemIndex: any) => {
    setMultiSearchValues([])
    setMultiSearchValues(
      bulkSearchValues.filter((item, index) => index !== deletedItemIndex)
    )
  }

  const handleMultiSearchChange = (event: any, index: number) => {
    const value = event.target.value
    bulkSearchValues[index] = value
    setMultiSearchValues([...bulkSearchValues])
  }

  const onClickForLink = () => {
    if (bulkSearchValues.length === 0) {
      setMultiSearchValues([...bulkSearchValues, searchValue, ''])
    } else {
      setMultiSearchValues([...bulkSearchValues, ''])
    }
  }
  const hanldeBulkRequest = async () => {
    if (!searchValue && bulkSearchValues.some((item: string) => !item)) return
    setError('')
    setLoading(true)
    try {
      const searchTerms =
        bulkSearchValues && bulkSearchValues.length > 0
          ? bulkSearchValues
          : [searchValue]
      let hasUrl = false
      const data = await Promise.all([
        ...searchTerms.map((value: string) => {
          const termIsUrl = isUrl(value)
          if (termIsUrl) hasUrl = true
          const handleRequest = termIsUrl ? handleUrlRequest : handleNameRequest
          return handleRequest(
            {
              [termIsUrl ? 'url' : 'name']: value,
              regions: selectedItems
            },
            account?.id || 0,
            authData
          ).then((result) => {
            return mapper(
              {
                term: value,
                isUrl: isUrl(value),
                data: result
              },
              selectedItems && selectedItems.length > 0 ? selectedItems[0] : ''
            )
          })
        })
      ])
      if (data.some((item: any) => !item)) {
        setLoading(false)
        const message = hasUrl
          ? "This URL doesn't look like it's a specific product page. Please try a new URL or consider entering a search term."
          : 'One or more seach terms failed.'
        setError(message)
      } else {
        setLoading(false)
        setData(data)
      }
    } catch (err: any) {
      setLoading(false)
      console.log(err)
      setError(err?.detail?.message)
    }
  }
  return (
    <div className={styles.root}>
      <h3 className={styles.title}>Interactive Classifier - Single Search</h3>
      <p>
        This classifier will help you find a tariff code (HS code) for an item
        for your paperwork. Enter a description of the product below.
      </p>
      <div className={styles.tableContainer}>
        <div className={styles.multiSelect}>
          <Select
            id="region"
            onChange={(value) => {
              if (value.target.value === 'global') {
                setSelectedItems([])
              } else {
                setSelectedItems([value.target.value])
              }
            }}
            labelText="Destination Region"
          >
            {regions.map((item, index) => (
              <SelectItem key={index} text={item.text} value={item.value} />
            ))}
          </Select>
        </div>
        {bulkSearchValues.length < 1 ? (
          <div className={styles.searchContainer}>
            <TextInput
              className={styles.searchInput}
              id="search-input"
              placeholder="Enter product name or description"
              labelText=""
              onChange={handleChange}
              value={searchValue}
              onKeyPress={onKeyPress}
              size="xl"
            />
          </div>
        ) : (
          <div className={styles.multiSearchContainer}>
            {bulkSearchValues.map((item, index) => (
              <div className={styles.container} key={index}>
                <TextInput
                  className={styles.searchInput}
                  id={`search-input-${index}`}
                  key={`search-input-${index}`}
                  onKeyPress={onKeyPress}
                  placeholder="Search by term"
                  labelText=""
                  onChange={(e) => handleMultiSearchChange(e, index)}
                  value={item}
                  size="xl"
                />
                {index !== 0 && (
                  <Button
                    iconDescription="Close Icon"
                    renderIcon={Close32}
                    className={styles.deleteBtn}
                    onClick={() => onDelete(index)}
                  ></Button>
                )}
              </div>
            ))}
          </div>
        )}
        <div className={styles.underlineContainer}>
          <div>
            <UnderlineLink
              text={'Search additional items'}
              onClick={onClickForLink}
            />
          </div>
        </div>
      </div>
      <p className={styles.error}>{error}</p>
      <div className="hsCodeButton">
        {!loading ? (
          <Button
            className={styles.findBtn}
            iconDescription="Search Icon"
            onClick={hanldeBulkRequest}
            size="field"
            disabled={searchValue.length === 0 && !bulkSearchValues[0]}
          >
            Find HS Code
          </Button>
        ) : (
          <InlineLoading
            style={{ position: 'absolute', marginTop: 55 }}
            description={'Loading...'}
            status={'active'}
          />
        )}
      </div>
    </div>
  )
}

export default InteractiveClassifier
