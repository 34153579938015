import { useContext, useEffect, useState } from 'react'
import { AccountContext } from '../../App'
import { getHistory, previewResult } from '../../lib/apiClient'
import { setErrorNotifications } from '../ErrorNotificationsProvider/action'
import { useStore } from '../ErrorNotificationsProvider/ErrorNotificationsProvider'
import styles from './SearchHistory.module.scss'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  DataTable,
  TableContainer,
  Loading
} from 'carbon-components-react'
import UnderlineLink from '../UnderlineLink/UnderlineLink'
import FilePreviewModal from '../FilePreviewModal/FilePreviewModal'
import { useAuth0 } from '@auth0/auth0-react'
import { getAuthData } from '../../lib/util'

const SearchHistory = () => {
  const { user } = useAuth0()
  const authData = getAuthData(user)
  const { account } = useContext(AccountContext)
  const [loading, setLoading] = useState(false)
  const { dispatch } = useStore()
  const [searchHistory, setSearchHistory] = useState<any>([])
  const [previewRows, setPreviewRows] = useState()
  const [previewTotal, setPreviewTotal] = useState(0)
  const [previewFileName, setPreviewFileName] = useState('')
  const [previewId, setPreviewId] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    if (account) {
      setLoading(true)
      getHistory(account.id || 0, authData.accountType)
        .then((data) => {
          if (data.error) {
            dispatch(
              setErrorNotifications({
                title: 'File upload error:',
                subtitle: data.error,
                open: true
              })
            )
            return
          }
          if (data) {
            const formattedData = data
              .filter((item: any) => item?.filePath)
              .sort((a: any, b: any) => b.id - a.id)
              .map((item: any, index: number) => ({
                id: `${item.id}`,
                createdAt: item?.createdAt
                  ? new Date(item?.createdAt).toLocaleDateString()
                  : '',
                fileName: item?.fileName,
                filePath: item?.filePath
              }))
            setSearchHistory(formattedData)
          }
        })
        .then(() => setLoading(false))
        .catch((err) => {
          console.log(err)
          setLoading(false)
          dispatch(
            setErrorNotifications({
              title: 'Search History Error:',
              subtitle: err.message,
              open: true
            })
          )
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.id])

  const previwHistory = async (historyId: any, fileName: string) => {
    try {
      const data = await previewResult(historyId, authData)
      if (data?.result.length > 0) {
        setPreviewTotal(data.total)
        const rows = data.result.map((item: any, index: number) => ({
          id: `${index + 1}`,
          sku: item?.sku || 'n/a',
          productName: item?.productName || 'n/a',
          productDescription: item?.productDescription || 'n/a',
          productPageUrl: item?.url || 'n/a',
          source: item?.source || 'n/a',
          codeUniversal: item?.codeUniversal,
          codeUs: item?.codeUs,
          codeEu: item?.codeEu
        }))
        setPreviewRows(rows)
        setPreviewFileName(fileName)
        setPreviewId(historyId)
        setOpenModal(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const downloadFile = async (historyId: any) => {
    const data = searchHistory.find((item: any) => item.id === historyId)
    const link = document.createElement('a')
    link.href = data.filePath
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const headers = [
    {
      key: 'createdAt',
      header: 'Date'
    },
    {
      key: 'fileName',
      header: 'File Name'
    },
    {
      key: 'actions',
      header: ''
    }
  ]

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h3>Search History</h3>
        <div className={styles.table}>
          {loading && (
            <div className={styles.loading}>
              <Loading withOverlay={false} />
            </div>
          )}
          {!loading && (
            <>
              <DataTable
                rows={searchHistory}
                headers={headers}
                stickyHeader={true}
              >
                {({
                  rows,
                  headers,
                  getHeaderProps,
                  getRowProps,
                  getTableProps,
                  getTableContainerProps
                }: any) => (
                  <TableContainer {...getTableContainerProps()}>
                    <Table {...getTableProps()} size="short">
                      <TableHead>
                        <TableRow>
                          {headers.map((header: any) => (
                            <TableHeader
                              key={header.key}
                              {...getHeaderProps({ header })}
                            >
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row: any) => (
                          <TableRow key={row.id} {...getRowProps({ row })}>
                            {row.cells.map((cell: any) => (
                              <TableCell key={cell.id}>
                                {cell.id.endsWith('actions') ? (
                                  <div className={styles.actions}>
                                    <UnderlineLink
                                      text="Preview"
                                      onClick={() => {
                                        const fileName = row.cells.find(
                                          (item: any) =>
                                            item.id?.endsWith('fileName')
                                        )?.value
                                        previwHistory(row.id, fileName)
                                      }}
                                    ></UnderlineLink>
                                    <UnderlineLink
                                      text="Download"
                                      onClick={() => {
                                        downloadFile(row.id)
                                      }}
                                    ></UnderlineLink>
                                  </div>
                                ) : (
                                  cell.value
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </DataTable>
            </>
          )}
        </div>
      </div>
      {openModal && (
        <FilePreviewModal
          id={previewId}
          fileName={previewFileName}
          rows={previewRows}
          total={previewTotal}
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          downloadFile={downloadFile}
        />
      )}
    </div>
  )
}

export default SearchHistory
