import styles from './ProgressBar.module.scss'

const ProgressBar = ({ color, progress }: any) => {
  const fill = {
    width: `${progress}%`,
    backgroundColor: color
  }

  return (
    <div className={styles.container}>
      <div style={fill} className={styles.fill}></div>
    </div>
  )
}

export default ProgressBar
