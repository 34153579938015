import {
  Modal,
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow
} from 'carbon-components-react'
import styles from './FilePreviewModal.module.scss'
import { Download16 } from '@carbon/icons-react'

const FilePreviewModal = ({
  openModal,
  handleCloseModal,
  id,
  fileName,
  rows,
  total,
  downloadFile
}: any) => {
  let headers = [
    {
      key: 'sku',
      header: 'SKU'
    },
    {
      key: 'productName',
      header: 'Product Name'
    },
    {
      key: 'productDescription',
      header: 'Product Description'
    },
    {
      key: 'url',
      header: 'Product Page URL'
    },
    {
      key: 'source',
      header: 'Source'
    }
  ]

  if (Object.keys(rows[0]).find((key) => key === 'codeUniversal')) {
    headers = [
      ...headers,
      {
        key: 'codeUniversal',
        header: 'Code - Universal'
      }
    ]
  }

  if (Object.keys(rows[0]).find((key) => key === 'codeEu')) {
    headers = [
      ...headers,
      {
        key: 'codeEu',
        header: 'Code - EU'
      }
    ]
  }

  if (Object.keys(rows[0]).find((key) => key === 'codeUs')) {
    headers = [
      ...headers,
      {
        key: 'codeUs',
        header: 'Code - US'
      }
    ]
  }

  return (
    <Modal
      open={openModal}
      modalHeading="File Preview"
      preventCloseOnClickOutside={true}
      onRequestClose={() => handleCloseModal()}
      passiveModal
      className={styles.filePreviewModal}
    >
      <p className={styles.sub}>Previewing the first 50 of {total} items.</p>
      <div className={styles.container}>
        <div className={styles.header}>
          <p>{fileName}</p>
        </div>
        <DataTable rows={rows} headers={headers} stickyHeader={true}>
          {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getTableContainerProps
          }: any) => (
            <TableContainer {...getTableContainerProps()}>
              <Table {...getTableProps()} size="short">
                <TableHead>
                  <TableRow>
                    {headers.map((header: any) => (
                      <TableHeader
                        key={header.key}
                        {...getHeaderProps({ header })}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row: any) => (
                    <TableRow key={row.id} {...getRowProps({ row })}>
                      {row.cells.map((cell: any) => (
                        <TableCell key={cell.id}>{cell.value}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DataTable>
      </div>
      <p className={styles.total}>Total of {total} items imported.</p>
      <div className={styles.actions}>
        <Button
          className={styles.btn}
          size="field"
          renderIcon={Download16}
          onClick={() => downloadFile(id)}
        >
          Download File
        </Button>
      </div>
    </Modal>
  )
}

export default FilePreviewModal
