import styles from './Sidebar.module.scss'
import HSCodesLogo from '../../assets/hscodes.svg'
import { Link as CarbonLink } from 'carbon-components-react'
import { withRouter, Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import TierTag from '../Tier/Tier'
import { getAuthData } from '../../lib/util'

const Sidebar = ({ location: router }: any) => {
  const { user, logout } = useAuth0()
  const authData = getAuthData(user)
  return (
    <div className={styles.root}>
      <div className={styles.logoContainer}>
        <img src={HSCodesLogo} alt="HSCodes.io logo"></img>
      </div>
      <div className={styles.content}>
        <p className={styles.section}>Search</p>
        <div className={styles.navigationItemContainer}>
          <div className={styles.bulletContainer}>
            {router?.pathname?.endsWith('/text') && (
              <div className={styles.bullet}></div>
            )}
          </div>
          <Link
            className={router?.pathname?.endsWith('/text') ? styles.active : ''}
            to="/classifier/text"
          >
            Single Search
          </Link>
        </div>
        <div className={styles.navigationItemContainer}>
          <div className={styles.bulletContainer}>
            {router?.pathname?.endsWith('/csv') && (
              <div className={styles.bullet}></div>
            )}
          </div>
          <Link
            className={router?.pathname?.endsWith('/csv') ? styles.active : ''}
            to="/classifier/csv"
          >
            CSV Upload
          </Link>
        </div>
        <div className={styles.fill}></div>
        <p className={styles.section}>My Account</p>
        {authData ? <TierTag type={authData.account_type} /> : null}
        <div className={styles.navigationItemContainer}>
          <div className={styles.bulletContainer}>
            {router?.pathname?.endsWith('/info') && (
              <div className={styles.bullet}></div>
            )}
          </div>
          <Link
            className={router?.pathname?.endsWith('/info') ? styles.active : ''}
            to="/account/info"
          >
            Account Information
          </Link>
        </div>
        <div className={styles.navigationItemContainer}>
          <div className={styles.bulletContainer}>
            {router?.pathname?.endsWith('/history') && (
              <div className={styles.bullet}></div>
            )}
          </div>
          <Link
            className={
              router?.pathname?.endsWith('/history') ? styles.active : ''
            }
            to="/account/history"
          >
            Search History
          </Link>
        </div>
        <div className={styles.navigationItemContainer}>
          <div className={styles.bulletContainer}>
            {router?.pathname?.endsWith('/billing') && (
              <div className={styles.bullet}></div>
            )}
          </div>
          <Link
            className={
              router?.pathname?.endsWith('/billing') ? styles.active : ''
            }
            to="/account/billing"
          >
            Payment & Billing
          </Link>
        </div>
        <div className={styles.navigationItemContainer}>
          <div className={styles.bulletContainer}>
            {router?.pathname?.endsWith('/faq') && (
              <div className={styles.bullet}></div>
            )}
          </div>
          <Link
            to={{
              pathname: 'https://hscodes.io/product-faq'
            }}
            target="_blank"
          >
            FAQ & Contacts
          </Link>
        </div>
        <div className={styles.navigationItemContainer}>
          <div className={styles.bulletContainer}> </div>
          <CarbonLink
            onClick={() => {
              logout({
                // eslint-disable-next-line no-restricted-globals
                returnTo: location.origin
              })
            }}
          >
            Sign Out
          </CarbonLink>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Sidebar)
