import React from 'react'
import styles from './SearchResult.module.scss'

import { DataTable, Button } from 'carbon-components-react'

const {
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow
} = DataTable

const SearchResult = ({ data, setData }: any) => {
  const headers = [
    {
      key: 'code',
      header: 'HS Code'
    },
    {
      key: 'term',
      header: 'Item'
    }
  ]

  const dataRows = data.map((item: any, index: number) => {
    return {
      id: `${index}:${item.code}`,
      ...item,
      term: item.isUrl ? item.name : item.term
    }
  })

  return (
    <div className={styles.root}>
      <h3>Search Results</h3>
      <h6>You're all set.</h6>
      <p>
        Use the following information to prepare your international shipment.
      </p>
      <div>
        <DataTable rows={dataRows} headers={headers}>
          {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getTableContainerProps
          }: any) => (
            <TableContainer
              className={styles.tableContainer}
              {...getTableContainerProps()}
            >
              <Table {...getTableProps()} className={styles.table}>
                <TableHead>
                  <TableRow>
                    <TableExpandHeader />
                    {headers.map((header: any, i: any) => (
                      <TableHeader key={i} {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row: any) => (
                    <React.Fragment key={row.id}>
                      <TableExpandRow {...getRowProps({ row })}>
                        {row.cells.map((cell: any) => (
                          <TableCell key={cell.id}>{cell.value}</TableCell>
                        ))}
                      </TableExpandRow>
                      <React.Fragment key={dataRows.id}>
                        <TableExpandedRow
                          colSpan={headers.length + 1}
                          className={styles.cell}
                        >
                          <div className={styles.expandedContent}>
                            <div className={styles.description}>
                              {
                                dataRows.find((p: any) => p.id === row.id)
                                  ?.description
                              }
                            </div>

                            <div className={styles.term}>
                              {dataRows.find((p: any) => p.id === row.id) && (
                                <a
                                  target="_blank"
                                  href={
                                    dataRows.find((p: any) => p.id === row.id)
                                      ?.url
                                  }
                                  rel="noreferrer"
                                >
                                  {
                                    dataRows.find((p: any) => p.id === row.id)
                                      ?.url
                                  }
                                </a>
                              )}
                            </div>
                          </div>
                        </TableExpandedRow>
                      </React.Fragment>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DataTable>
      </div>
      <div className={styles.searchDisclaimer}>
        <p>
          This code is a computer AI generated code. If it looks wrong, try
          entering the description of your product using more universal terms
          (e.g. laptop versus ASUS Model 100010).
        </p>
      </div>
      <div className={styles.btnWrapper}>
        <Button
          size="field"
          className={styles.btn}
          onClick={() => setData(null)}
        >
          New Search
        </Button>
      </div>
    </div>
  )
}

export default SearchResult
