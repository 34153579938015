import { InlineNotification } from 'carbon-components-react'
import { useStore } from '../ErrorNotificationsProvider/ErrorNotificationsProvider'
import styles from './ErrorNotificationsModal.module.scss'
const ErrorNotificationsModal = () => {
  const { state } = useStore()
  return (
    state.errorNotifications.open && (
      <InlineNotification
        className={styles.ErrorNotificationsModal}
        title={state.errorNotifications.title}
        subtitle={state.errorNotifications.subtitle}
        kind="error"
        lowContrast
      />
    )
  )
}

export default ErrorNotificationsModal
