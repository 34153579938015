import { Modal, Button } from 'carbon-components-react'
import styles from './DownloadTemplateModal.module.scss'
import Image from '../../assets/image.png'
import { Download16 } from '@carbon/icons-react'
const DownloadTemplateModal = ({ openModal, handleCloseModal }: any) => {
  return (
    <Modal
      open={openModal}
      modalHeading="File Upload Guide"
      preventCloseOnClickOutside={true}
      onRequestClose={() => handleCloseModal()}
      passiveModal
    >
      <div className={styles.descriptionContainer}>
        <br />
        <p className={styles.description}>
          The .csv file must contain a product name column. Product
          <br />
          description, product page url, and SKU number are also
          <br />
          recommended for better code search accuracy. Please see the
          <br />
          following image for guidance, or download the template .csv file.
        </p>
      </div>
      <div className={styles.container}>
        <div>
          <img className={styles.picture} src={Image} alt="informative steps" />
        </div>
        <div className={styles.stepsContainer}>
          <div className={styles.stepBox}>
            1. Give your file a clear,
            <br />
            identifiable name
            <br />
            including the merchant name.
          </div>
          <div className={styles.stepBox}>
            2. Make sure to have at
            <br /> least the product
            <br />
            name column.
          </div>
          <div className={styles.stepBox}>
            A product description,
            <br />
            while optional, can also
            <br />
            help provide more accurate results.
          </div>
        </div>
      </div>
      <Button
        className={styles.downloadButton}
        size="field"
        renderIcon={Download16}
      >
        <a href="/hscode-template.csv" download>
          Download Template
        </a>
      </Button>
    </Modal>
  )
}

export default DownloadTemplateModal
