import { useAuth0 } from '@auth0/auth0-react'
import { Modal } from 'carbon-components-react'
import { useContext, useState } from 'react'
import { AccountContext } from '../../App'
import { acceptTermsForAccountId } from '../../lib/apiClient'
import styles from './EndUserTerms.module.scss'

const EndUserTerms = () => {
  const { account } = useContext(AccountContext)
  const [open, setOpen] = useState(true)
  const { user, logout } = useAuth0()
  const userMetadata = user ? user['https://hs-classify.app/user_metadata'] : {}
  const HAS_ACCEPTED_TERMS = 'key-hasAcceptedTerms'

  const acceptTerms = async () => {
    try {
      const result = await acceptTermsForAccountId(account?.id || 0)
      if (result) {
        setOpen(false)
      }
    } catch (ex) {
      console.log(ex)
    }
  }

  const hasAcceptedTerms =
    userMetadata?.hasAcceptedTerms || account?.hasAcceptedTerms
  return !hasAcceptedTerms ? (
    <Modal
      open={open}
      modalHeading="End User Terms"
      primaryButtonText="Accept terms"
      secondaryButtonText="Log me out"
      onRequestClose={() =>
        logout({
          returnTo: origin
        })
      }
      preventCloseOnClickOutside={true}
      onSecondarySubmit={() =>
        logout({
          returnTo: origin
        })
      }
      onRequestSubmit={() => acceptTerms()}
    >
      <div className={styles.text}>
        <br />
        <p>
          These End User Terms constitute a legally binding agreement (the
          “Agreement”) between you and HSCodes.io, Inc. (“HSCodes.io”) governing
          your access to and use of the HSCodes.io tariff classification service
          and related data (collectively, the “HSCodes.io Services”). By
          entering into this Agreement, and/or by accessing or using the
          HSCodes.io Services, you expressly acknowledge that you have read,
          understood, and agree to be bound by this Agreement. This Agreement
          applies to all individuals and entities who access or use the
          HSCodes.io Services (“you,” or “your”). HSCodes.io reserves the right,
          at its sole discretion, to change, modify, add or remove portions of
          this Agreement, at any time, by posting changes to this page. Your
          continued access to or use of the HSCodes.io Services after such
          posting confirms your consent to be bound by this Agreement, as
          amended. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS
          OF THIS AGREEMENT, YOU MAY NOT ACCESS OR USE THE HSCODES.IO SERVICES.
        </p>
        <br />
        <p className={styles.underlineP}>1.​Proprietary Rights.</p>
        <p style={{ margin: 0, display: 'inline', marginBottom: '1rem' }}>
          {' '}
          Subject to the terms and conditions of this Agreement, you are hereby
          granted a limited, non-exclusive, non-transferable, non-sublicensable,
          freely revocable right to access and use the HSCodes.io Services only
          as permitted by the features of the HSCodes.io Services and in
          accordance with this Agreement. HSCodes.io retains all right, title,
          and interest in and to the HSCodes.io Services and reserves all rights
          not expressly granted in this Agreement.
        </p>
        <br />
        <br />
        <p className={styles.underlineP}>2.​Restrictions.</p>
        <p style={{ margin: 0, display: 'inline', marginBottom: '1rem' }}>
          {' '}
          You and any third parties accessing the HSCodes.io Services on your
          behalf shall not: (i) copy, modify, or create derivative works of the
          HSCodes.io Services; (ii) reverse engineer, disassemble, decompile,
          modify, or alter the HSCodes.io Services; (iii) remove, circumvent,
          disable, damage, or otherwise interfere with or disrupt the integrity
          or performance of the HSCodes.io Services or any security-related
          features therein; (iv) input, upload, transmit, or otherwise provide
          to or through the HSCodes.io Services, any information or materials
          that are unlawful or injurious, or contain, transmit, send, or store
          material containing harmful computer code, files, scripts, agents, or
          programs; (v) rent, lease, loan, resell, sublicense, distribute,
          monetize, or otherwise commercially exploit or make available the
          HSCodes.io Services to any third party; (vi) provide database services
          to any third party using the HSCodes.io Services; or (vii) use the
          HSCodes.io Services as a basis to compete with HSCodes.io or its
          business.
        </p>
        <br />
        <br />
        <p className={styles.underlineP}>3.​Your Consent.</p>
        <p style={{ margin: 0, display: 'inline', marginBottom: '1rem' }}>
          {' '}
          By using the HSCodes.io Services, including uploading any product
          information, you fully acknowledge, and expressly consent to,
          HSCodes.io’s accessing and data gathering activities on your, or your
          company’s website.
        </p>
        <br />
        <br />
        <p className={styles.underlineP}>4.​Indemnification.</p>
        <p style={{ margin: 0, display: 'inline', marginBottom: '1rem' }}>
          {' '}
          You agree to defend, indemnify, and hold HSCodes.io and its officers,
          directors, employees, agents, and affiliates (the “HSCodes.io
          Entities”) harmless from any and all third-party claims, proceedings,
          damages, injuries, liabilities, losses, costs and expenses (including
          reasonable attorneys’ fees and litigation expenses), arising out of or
          relating to: (i) your access to or use of the HSCodes.io Service; (ii)
          your violation of any portion of this Agreement or any applicable law,
          rule, or regulation; and (iii) your violation of any third-party
          right.
        </p>
        <br />
        <br />
        <p className={styles.underlineP}>5.​Warranty Disclaimer.</p>
        <p style={{ margin: 0, display: 'inline', marginBottom: '1rem' }}>
          {' '}
          THE HSCODES.IO SERVICES ARE PROVIDED “AS IS.” HSCODES.IO MAKES NO
          REPRESENTATION OR WARRANTY OF ANY KIND WHETHER EXPRESS, IMPLIED
          (EITHER IN FACT OR BY OPERATION OF LAW), OR STATUTORY, AS TO ANY
          MATTER WHATSOEVER. HSCODES.IO EXPRESSLY DISCLAIMS ALL IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          QUALITY, ACCURACY, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES
          ARISING FROM COURSE OF DEALING, USAGE OR TRADE PRACTICE. HSCODES.IO
          DOES NOT WARRANT THAT THE HSCODES.IO SERVICES ARE ERROR-FREE OR WILL
          BE SECURE OR UNINTERRUPTED, THAT THE RESULTS OF THE USE THEREOF WILL
          BE CORRECT OR ACCURATE, OR THAT YOU WILL NOT BE ASSESSED ANY TAXES,
          FEES, OR PENALTIES AS A RESULT OF THE USE THEREOF. HSCODES.IO
          EXERCISES NO CONTROL OVER AND EXPRESSLY DISCLAIMS ANY LIABILITY
          RELATED TO, ARISING OUT OF, OR BASED UPON ANY THIRD-PARTY SITES IN
          RELATION TO THE HSCODES.IO SERVICES. YOU HEREBY AGREE THAT HSCODES.IO
          IS NOT AND WILL NOT BE LIABLE FOR ANY LOSSES RESULTING OR ARISING OUT
          OF INACCURATE OR UNAVAILABLE DATA OR HARMONIZED SCHEDULE TARIFF CODES
          OR ANY INTERRUPTION IN THE HSCODES.IO SERVICES, AND THAT THE
          SEMATNICS3 SERVICES ARE NOT COVERED BY ANY GUARANTEE OF SERVICE OR
          COMPENSATION OF ANY SORT.
        </p>
        <br />
        <br />
        <p className={styles.underlineP}>6.​Limitation of Liability.</p>
        <p style={{ margin: 0, display: 'inline', marginBottom: '1rem' }}>
          {' '}
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
          THE HSCODES.IO ENTITIES OR ITS LICENSORS BE LIABLE FOR ANY DIRECT,
          INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
          DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS,
          GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES, INCURRED BY YOU OR
          ANY THIRD PARTY, WHETHER IN AN ACTION IN CONTRACT OR TORT, ARISING
          FROM THE USE OF, OR INABILITY TO USE, THE HSCODES.IO SERVICES, EVEN IF
          HSCODES.IO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR
          LOSSES. NOTWITHSTANDING THE FOREGOING, THE TOTAL LIABILITY OF THE
          HSCODES.IO ENTITIES AND ITS LICENSORS, WHETHER IN CONTRACT, WARRANTY,
          TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), PRODUCT LIABILITY,
          STRICT LIABILITY, OR ANY OTHER THEORY, ASSOCIATED WITH ANY CLAIM
          ARISING OUT OF OR RELATING TO USE OF OR ACCESS TO THE HSCODES.IO
          SERVICES FOR ANY REASON WHATSOEVER SHALL BE LIMITED TO ONE HUNDRED
          DOLLARS ($100).
        </p>
      </div>
    </Modal>
  ) : (
    <></>
  )
}

export default EndUserTerms
