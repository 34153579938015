import { useContext, useState } from 'react'
import CSVImport from './CSVImport/CSVImport'
import CSVFilePreview from './CSVFilePreview/CSVFilePreview'
//import CSVResults from './CSVResults/CSVResults'
import CSVGenerating from './CSVGenerating/CSVGenerating'
import { AccountContext } from '../../App'
function CSVContainer({ user }: any) {
  const { account } = useContext(AccountContext)
  const [file, setFile] = useState<File | null>(null)
  const [fileName, setFileName] = useState('')
  const [step, setStep] = useState(0)

  const regions = [
    {
      text: 'Universal',
      value: 'global'
    },
    {
      text: 'EU - European Union',
      value: 'eu'
    },
    {
      text: 'US - United States',
      value: 'usa'
    }
  ]
  const initialSelected = account?.preferredRegions
    ? regions.filter((region) =>
        account.preferredRegions?.includes(region.value)
      )
    : []

  const [selectedItems, setSelectedItems] = useState<any[]>(
    initialSelected.length === 2
      ? [...initialSelected, regions.find((item) => item.value === 'global')]
      : initialSelected
  )

  return (
    <div className="container">
      {step === 0 && (
        <CSVImport
          file={file}
          fileName={fileName}
          setFile={setFile}
          setFileName={setFileName}
          setStep={setStep}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          user={user}
          regions={regions}
        />
      )}
      {step === 1 && (
        <CSVFilePreview
          setStep={setStep}
          fileName={fileName}
          file={file}
          user={user}
        />
      )}
      {step === 2 && (
        <CSVGenerating
          setStep={setStep}
          fileName={fileName}
          file={file}
          setFileName={setFileName}
          setSelectedItems={setSelectedItems}
          setFile={setFile}
          selectedItems={selectedItems}
          user={user}
        />
      )}
    </div>
  )
}

export default CSVContainer
