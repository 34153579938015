import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()
  useEffect(() => {
    loginWithRedirect()
  }, [loginWithRedirect])

  return <p>Loading...</p>
}

export default LoginButton
