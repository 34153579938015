import { useContext, useState } from 'react'
import styles from './Register.module.scss'
import HSCodesLogo from '../../assets/hscodes.svg'
import HSCodesRegistrationScreenIllustration from '../../assets/hscodes-register-screen-illustration.svg'
import { Button, Link, MultiSelect, TextInput } from 'carbon-components-react'
import { useAuth0 } from '@auth0/auth0-react'
import { registerAccount } from '../../lib/apiClient'
import { AccountContext } from '../../App'

const Register = () => {
  const { user, logout } = useAuth0()
  const { setAccount } = useContext(AccountContext)
  const industries = [
    {
      text: 'Postal Agency',
      value: 'Postal Agency'
    },
    {
      text: 'Customs Agency',
      value: 'Customs Agency'
    },
    {
      text: 'Express Courier',
      value: 'Express Courier'
    },
    {
      text: '3PL',
      value: '3PL'
    },
    {
      text: 'Ecommerce Merchant',
      value: 'Ecommerce Merchant'
    },
    {
      text: 'Ecommerce Marketplace',
      value: 'Ecommerce Marketplace'
    },
    {
      text: 'Other',
      value: 'Other'
    }
  ]
  const regions = [
    {
      text: 'US - United States',
      value: 'usa'
    },
    {
      text: 'EU - European Union',
      value: 'eu'
    }
  ]
  const [pristine, setPristine] = useState(true)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [businessURL, setBusinessURL] = useState('')
  const [selectedIndustries, setSelectedInustries] = useState<any[]>([])
  const [selectedRegions, setSelectedRegions] = useState<any[]>([])

  const handleSubmit = async () => {
    setPristine(false)
    if (!user?.email || !firstName || !lastName || !businessName) return

    try {
      const payload = prepareFormData(user.email)
      const account = await registerAccount(payload)
      setAccount(account)
    } catch (err) {
      console.log(err)
    }
  }

  const prepareFormData = (email: string) => {
    return {
      email,
      firstName,
      lastName,
      businessName,
      businessURL,
      industry: selectedIndustries.map((item) => item.value),
      region: selectedRegions.map((item) => item.value)
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.illustrationContainer}>
          <div className={styles.logoContainer}>
            <img src={HSCodesLogo} alt="HSCodes.io logo"></img>
          </div>
          <div className={styles.illustration}>
            <img
              src={HSCodesRegistrationScreenIllustration}
              alt="Register screen illustration"
            ></img>
          </div>
        </div>
        <div className={styles.formContainer}>
          <div className={styles.form}>
            <h2 className={styles.header}>Registration Form</h2>
            <p className={styles.subheader}>
              Please tell us a little more about you.
            </p>
            <div className={styles.row}>
              <div className={styles.shortInput}>
                <TextInput
                  className={styles.input}
                  id="firstName"
                  key="firstName"
                  placeholder="Your first name"
                  labelText="First Name*"
                  onChange={(e) => {
                    setFirstName(e.target.value)
                  }}
                  size="md"
                  invalid={!pristine && !firstName ? true : false}
                  invalidText="First Name is required"
                />
              </div>
              <div className={styles.shortInput}>
                <TextInput
                  className={styles.input}
                  id="lastName"
                  key="lastName"
                  placeholder="Your last name"
                  labelText="Last Name*"
                  onChange={(e) => {
                    setLastName(e.target.value)
                  }}
                  size="md"
                  invalid={!pristine && !lastName ? true : false}
                  invalidText="Last Name is required"
                />
              </div>
            </div>
            <div className={styles.row}>
              <TextInput
                className={styles.input}
                id="businessName"
                key="businessName"
                placeholder="Your business name"
                labelText="Business Name*"
                onChange={(e) => {
                  setBusinessName(e.target.value)
                }}
                size="md"
                invalid={!pristine && !businessName ? true : false}
                invalidText="Business Name is required"
              />
            </div>
            <div className={styles.row}>
              <TextInput
                className={styles.input}
                id="businessURL"
                key="businessURL"
                placeholder="Your business URL"
                labelText="Business URL"
                onChange={(e) => {
                  setBusinessURL(e.target.value)
                }}
                size="md"
                invalidText="Please enter valid URL address"
              />
            </div>
            <div className={styles.row}>
              <div className={styles.shortInput}>
                <MultiSelect
                  titleText="Industry"
                  useTitleInItem={false}
                  label={
                    selectedIndustries.length > 0
                      ? `${selectedIndustries.length} ${
                          selectedIndustries.length > 1
                            ? 'industries'
                            : 'industry'
                        } selected`
                      : 'Choose one or more options'
                  }
                  id="industry"
                  items={industries}
                  itemToString={(item) => (item ? item.text : '')}
                  onChange={(event) =>
                    setSelectedInustries(event.selectedItems)
                  }
                />
              </div>
              <div className={styles.shortInput}>
                <MultiSelect
                  titleText="Country / Region"
                  useTitleInItem={false}
                  label={
                    selectedRegions.length > 0
                      ? `${selectedRegions.length}  ${
                          selectedRegions.length > 1 ? 'regions' : 'region'
                        } selected`
                      : 'Choose one or more options'
                  }
                  id="region"
                  items={regions}
                  itemToString={(item) => (item ? item.text : '')}
                  onChange={(event) => setSelectedRegions(event.selectedItems)}
                />
              </div>
            </div>
            <p className={styles.requiredDisclaimer}>*Required fields</p>
          </div>
          <div className={styles.formActions}>
            <div className={styles.linkContainer}>
              <Link
                className={styles.link}
                onClick={() => {
                  logout({
                    // eslint-disable-next-line no-restricted-globals
                    returnTo: location.origin
                  })
                }}
              >
                Back
                <div className={styles.underline}></div>
              </Link>
            </div>
            <Button
              type="button"
              className={styles.submit}
              iconDescription="Search Icon"
              onClick={handleSubmit}
              size="field"
              disabled={false}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
