import { Button, Link } from 'carbon-components-react'
import { useContext, useEffect, useState } from 'react'
import { fileResult } from '../../../lib/apiClient'
import styles from './CSVGenerating.module.scss'
import { getAuthData } from '../../../lib/util'
import { setErrorNotifications } from '../../ErrorNotificationsProvider/action'
import { useStore } from '../../ErrorNotificationsProvider/ErrorNotificationsProvider'
import Image from '../../../assets/Vector.png'
import { AccountContext } from '../../../App'

const CSVGenerating = ({
  setStep,
  fileName,
  file,
  setFileName,
  selectedItems,
  setSelectedItems,
  setFile,
  user
}: any) => {
  const { account } = useContext(AccountContext)
  const [loading, setLoading] = useState(false)
  const authData = getAuthData(user)
  const { dispatch } = useStore()

  useEffect(() => {
    setLoading(true)
    const regions = selectedItems.map(
      (selectedItem: { value: string }) => selectedItem.value
    )
    fileResult(fileName, file, authData, regions, account?.id)
      .then((data) => {
        if (data.error) {
          dispatch(
            setErrorNotifications({
              title: 'File upload error:',
              subtitle: data.error,
              open: true
            })
          )
          setStep(0)
          return
        }
      })
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>Your HS Codes are generating...</h3>
      </div>
      {!loading && (
        <div className={styles.container}>
          <div className={styles.imageLink}>
            <div className={styles.table}>
              <img src={Image} className={styles.image} alt="Tick icon" />
            </div>
            <div className={styles.iconLink}>
              <Link
                className={styles.link}
                onClick={() => {
                  setStep(0)
                  setFileName('')
                  setSelectedItems([])
                  setFile(null)
                }}
              >
                Download link will be sent through email <br />{' '}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;when file processing
                is complete
              </Link>
            </div>
          </div>
          <div className={styles.button}>
            <Button
              iconDescription="Start a New Search"
              size="field"
              onClick={() => {
                setStep(0)
                setFileName('')
                setSelectedItems([])
                setFile(null)
              }}
            >
              Start a New Search
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
export default CSVGenerating
