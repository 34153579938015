import { Account } from '../types/account.type'

const base = `${process.env.REACT_APP_PROXY}/api/`
const defaultHeaders = {
  'Content-type': 'application/json'
}

const handleNameRequest = (
  query: any,
  accountId: any,
  authData: {
    semantics3_key: string
    semantics3_secret: string
    account_type: string
    allow_url_crawling: boolean
  } = {
    semantics3_key: '',
    semantics3_secret: '',
    account_type: 'free',
    allow_url_crawling: false
  }
) => {
  return fetch(`${base}/categorize/hscodes`, {
    method: 'POST',
    headers: new Headers({
      ...defaultHeaders,
      'X-API-KEY': authData.semantics3_key,
      'X-API-SECRET': authData.semantics3_secret,
      'X-ACCOUNT-TYPE': authData.account_type,
      'X-ALLOW-URL-CRAWLING': `${authData.allow_url_crawling}`
    }),
    body: JSON.stringify({
      ...query,
      accountId
    })
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
    })
    .catch((err) => {
      console.log(err)
      throw new Error(err)
    })
}

const handleUrlRequest = (
  query: any,
  accountId: any,
  authData: {
    semantics3_key: string
    semantics3_secret: string
    account_type: string
    allow_url_crawling: boolean
  } = {
    semantics3_key: '',
    semantics3_secret: '',
    account_type: 'free',
    allow_url_crawling: false
  }
) => {
  return fetch(
    `${base}/categorize/hscodes/predict/url/?accountId=${accountId}&url=${query.url}`,
    {
      method: 'GET',
      headers: new Headers({
        'X-API-KEY': authData.semantics3_key,
        'X-API-SECRET': authData.semantics3_secret,
        'X-ACCOUNT-TYPE': authData.account_type,
        'X-ALLOW-URL-CRAWLING': `${authData.allow_url_crawling}`
      })
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
    })
    .catch((err) => {
      console.log(err)
      throw new Error(err)
    })
}

const previewCSV = (
  file: any,
  accountId: any,
  authData: {
    semantics3_key: string
    semantics3_secret: string
    limit: number
    account_type: string
    allow_url_crawling: boolean
  } = {
    semantics3_key: '',
    semantics3_secret: '',
    limit: 100,
    account_type: 'free',
    allow_url_crawling: false
  }
) => {
  const data = new FormData()
  data.append('file', file, file.name)
  data.append('limit', authData.limit?.toString() || '100')
  data.append('accountId', `${accountId}`)

  return fetch(`${base}/csv/preview`, {
    method: 'POST',
    headers: new Headers({
      'X-API-KEY': authData.semantics3_key,
      'X-API-SECRET': authData.semantics3_secret,
      'X-ACCOUNT-TYPE': authData.account_type,
      'X-ALLOW-URL-CRAWLING': `${authData.allow_url_crawling}`
    }),
    body: data
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
      return res.json().then((data) => {
        return {
          error: data?.message || 'Something went wrong.'
        }
      })
    })
    .catch((err) => {
      throw new Error(err)
    })
}

const previewResult = (historyId: any, accountType: string) => {
  const query = {
    historyId
  }
  return fetch(`${base}/csv/preview-result`, {
    method: 'POST',
    headers: new Headers({
      ...defaultHeaders,
      'X-ACCOUNT-TYPE': accountType
    }),
    body: JSON.stringify(query)
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
      return res.json().then((data) => {
        return {
          error: data?.message || 'Something went wrong.'
        }
      })
    })
    .catch((err) => {
      throw new Error(err)
    })
}

const fileResult = (
  fileName: string,
  file: any,
  authData: {
    semantics3_key: string
    semantics3_secret: string
    limit: number
    account_type: string
    allow_url_crawling: boolean
  } = {
    semantics3_key: '',
    semantics3_secret: '',
    limit: 100,
    account_type: 'free',
    allow_url_crawling: false
  },
  regions: any,
  accountId?: number
) => {
  const data = new FormData()
  data.append('fileName', fileName)
  data.append('file', file, file.name)
  data.append('regions', JSON.stringify(regions))
  data.append('limit', authData.limit?.toString() || '100')
  data.append('accountId', `${accountId}`)

  return fetch(`${base}/csv/handle`, {
    method: 'POST',
    headers: new Headers({
      'X-API-KEY': authData.semantics3_key,
      'X-API-SECRET': authData.semantics3_secret,
      'X-ACCOUNT-TYPE': authData.account_type,
      'X-ALLOW-URL-CRAWLING': `${authData.allow_url_crawling}`
    }),
    body: data
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
      return res.json().then((data) => {
        return {
          error: data?.message || 'Something went wrong.'
        }
      })
    })
    .catch((err) => {
      console.log(err)
      throw new Error(err)
    })
}

const getAccount = (email: string, accountType?: string) => {
  return fetch(`${base}/accounts?email=${email}`, {
    method: 'GET',
    headers: new Headers({
      ...defaultHeaders,
      'X-ACCOUNT-TYPE': accountType || ''
    })
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
      throw new Error('No account')
    })
    .catch((err) => {
      console.log(err)
      throw new Error(err)
    })
}

const registerAccount = (payload: Account) => {
  return fetch(`${base}/accounts`, {
    method: 'POST',
    headers: new Headers({
      ...defaultHeaders
    }),
    body: JSON.stringify(payload)
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
    })
    .catch((err) => {
      console.log(err)
      throw new Error(err)
    })
}

const updateAccount = (id: number, payload: Account) => {
  return fetch(`${base}/accounts/${id}`, {
    method: 'POST',
    headers: new Headers({
      ...defaultHeaders
    }),
    body: JSON.stringify(payload)
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
    })
    .catch((err) => {
      console.log(err)
      throw new Error(err)
    })
}

const acceptTermsForAccountId = (id: number) => {
  return fetch(`${base}/accounts/${id}/terms`, {
    method: 'POST',
    headers: new Headers({
      ...defaultHeaders
    }),
    body: JSON.stringify({})
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
    })
    .catch((err) => {
      console.log(err)
      throw new Error(err)
    })
}

const getHistory = (accountId: number, accountType: string) => {
  return fetch(`${base}/history/${accountId}`, {
    method: 'GET',
    headers: new Headers({
      ...defaultHeaders,
      'X-ACCOUNT-TYPE': accountType
    })
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
    })
    .catch((err) => {
      console.log(err)
      throw new Error(err)
    })
}

export {
  handleNameRequest,
  handleUrlRequest,
  previewCSV,
  previewResult,
  fileResult,
  getAccount,
  registerAccount,
  updateAccount,
  getHistory,
  acceptTermsForAccountId
}
