import { useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import CSVContainer from '../CSVContainer/CSVContainer'
import ErrorNotificationsModal from '../ErrorNotificationsModal/ErrorNotificationsModal'
import InteractiveClassifier from '../InteractiveClassifier/InteractiveClassifier'
import SearchResult from '../SearchResult/SearchResult'
import styles from './Main.module.scss'
const Main = () => {
  const { user } = useAuth0()
  return (
    <div className={styles.root}>
      <ErrorNotificationsModal />
      <div className={styles.classifier}>
        <Switch>
          <Route exact path="/classifier/text">
            <TextBasedSearch />
          </Route>
          <Route exact path="/classifier/csv">
            <CSVContainer user={user} />
          </Route>
          <Redirect to="classifier/text" />
        </Switch>
      </div>
    </div>
  )
}

const TextBasedSearch = () => {
  const { user } = useAuth0()
  const [data, setData] = useState(null)
  return !data ? (
    <InteractiveClassifier setData={setData} user={user} />
  ) : (
    <SearchResult data={data} setData={setData} />
  )
}

export default withRouter(Main)
