import { createContext, useContext, useReducer } from 'react'

const initialState = {
  errorNotifications: {
    title: '',
    subtitle: '',
    open: false
  }
} as any

const ErrorNotificationsContext = createContext(initialState)

const ErrorNotificationsProvider = ({ children }: any) => {
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case 'SET_ERROR_NOTIFICATION':
        return {
          ...state,
          errorNotifications: { ...action.payload }
        }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ErrorNotificationsContext.Provider value={{ state, dispatch }}>
      {children}
    </ErrorNotificationsContext.Provider>
  )
}
export const useStore = () => useContext(ErrorNotificationsContext)
export default ErrorNotificationsProvider
