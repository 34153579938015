import { useContext, useEffect, useState } from 'react'
import styles from './AccountInfo.module.scss'
import {
  Button,
  InlineLoading,
  MultiSelect,
  TextInput
} from 'carbon-components-react'
import { useAuth0 } from '@auth0/auth0-react'
import { updateAccount } from '../../lib/apiClient'
import { AccountContext } from '../../App'
import { getAuthData } from '../../lib/util'
import ProgressBar from '../ProgressBar/ProgressBar'

const AccountInfo = () => {
  const { user } = useAuth0()
  const { account, setAccount } = useContext(AccountContext)

  const industries = [
    {
      text: 'Transporting',
      value: 'Transporting'
    },
    {
      text: 'IT - Consulting',
      value: 'IT - Consulting'
    }
  ]
  const regions = [
    {
      text: 'US - United States',
      value: 'usa'
    },
    {
      text: 'EU - European Union',
      value: 'eu'
    }
  ]

  const [accountType, setAccountType] = useState('')
  const [pristine, setPristine] = useState(true)
  const [firstName, setFirstName] = useState(account?.firstName || '')
  const [lastName, setLastName] = useState(account?.lastName || '')
  const [businessName, setBusinessName] = useState(account?.businessName || '')
  const [businessURL, setBusinessURL] = useState(account?.businessUrl || '')
  const [validBusinessURL, setValidBusinessURL] = useState(false)
  const [selectedIndustries, setSelectedInustries] = useState<any[]>(
    account?.industry
      ? industries.filter((industry) =>
          account.industry?.includes(industry.value)
        )
      : []
  )
  const [selectedRegions, setSelectedRegions] = useState<any[]>(
    account?.region
      ? regions.filter((region) => account.region?.includes(region.value))
      : []
  )

  const [selectedPreferedRegions, setSelectedPreferedRegions] = useState<any[]>(
    account?.preferredRegions
      ? regions.filter((region) =>
          account.preferredRegions?.includes(region.value)
        )
      : []
  )

  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (user) {
      const { account_type } = getAuthData(user)
      setAccountType(account_type)
    }
  }, [user])

  useEffect(() => {
    if (account) {
      const count = account?.monthlySearchCount
      if (count) {
        setProgress((count / 5000) * 100)
      }
    }
  }, [account, account?.monthlySearchCount])

  const handleSubmit = async () => {
    setPristine(false)
    setValidBusinessURL(isValidURL(businessURL))
    if (
      !user?.email ||
      !account?.id ||
      !firstName ||
      !lastName ||
      !businessName ||
      (businessURL && !isValidURL(businessURL))
    )
      return
    setLoading(true)
    try {
      const payload = prepareFormData()
      console.log(payload)
      const updatedAccount = await updateAccount(account.id, payload)
      if (updatedAccount) {
        setAccount(updatedAccount)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const prepareFormData = () => {
    return {
      email: '',
      firstName,
      lastName,
      businessName,
      businessURL,
      industry: selectedIndustries.map((item) => item.value),
      region: selectedRegions.map((item) => item.value),
      preferredRegions: selectedPreferedRegions.map((item) => item.value)
    }
  }

  const existItem = (region: string) => {
    if (selectedPreferedRegions.length === 0) return false
    const exist = selectedPreferedRegions.some(
      (selectedItems: any) => selectedItems.text === region
    )
    return exist
  }

  const returnSelectedPreferedRegions = (region: string) => {
    const formattedArray = selectedPreferedRegions.find(
      (selectedItems: any) => selectedItems.text === region
    )
    setSelectedPreferedRegions(formattedArray)
  }

  const isValidURL = (url: string): boolean => {
    return /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%\\/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(
      url
    )
      ? true
      : false
  }

  return (
    <div className={styles.root}>
      <div className={styles.page}>
        <p className={styles.pageTitle}>Account Overview</p>
        {accountType && (
          <>
            <div className={styles.title}>
              <p>Your plan</p>
            </div>
            <div className={styles.accountType}>
              <div
                className={`${styles.illustration} ${
                  accountType === 'pro' ? styles.pro : styles.free
                }`}
              >
                {accountType === 'pro' ? (
                  <h1>hscodes.io Courier</h1>
                ) : (
                  <h1>hscodes.io Free</h1>
                )}
              </div>
              <div className={styles.content}>
                <div className={styles.points}>
                  {accountType === 'pro' ? (
                    <>
                      <p>✓ Unlimited generated codes every month</p>
                      <p>✓ Saved search history and file uploads</p>
                      <p>✓ URL crawling service for better search accuracy</p>
                    </>
                  ) : (
                    <>
                      <p>✓ 5,000 generated codes every month</p>
                      <p>✓ Saved search history and file uploads</p>
                    </>
                  )}
                </div>
                <div className={styles.barContainer}>
                  <div className={styles.textBar}>
                    {accountType === 'pro' ? (
                      <>
                        <p>Available HS Codes</p>
                        <p>Unlimited</p>
                      </>
                    ) : (
                      <>
                        <p>Generated HS Codes</p>
                        <p>{account?.monthlySearchCount}/5000</p>
                      </>
                    )}
                  </div>

                  <ProgressBar
                    color={accountType === 'pro' ? '#ff9b3d' : '#003fc2'}
                    progress={accountType === 'pro' ? 100 : progress}
                  ></ProgressBar>
                </div>
              </div>
            </div>
          </>
        )}
        <div className={styles.title}>
          <p>Your Information</p>
        </div>
        <div className={styles.container}>
          <div className={styles.formContainer}>
            <div className={styles.form}>
              <div className={styles.row}>
                <div className={styles.shortInput}>
                  <TextInput
                    className={styles.input}
                    id="firstName"
                    key="firstName"
                    placeholder="Your first name"
                    labelText="First Name*"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value)
                    }}
                    size="md"
                    invalid={!pristine && !firstName ? true : false}
                    invalidText="First Name is required"
                  />
                </div>
                <div className={styles.shortInput}>
                  <TextInput
                    className={styles.input}
                    id="lastName"
                    key="lastName"
                    placeholder="Your last name"
                    labelText="Last Name*"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value)
                    }}
                    size="md"
                    invalid={!pristine && !lastName ? true : false}
                    invalidText="Last Name is required"
                  />
                </div>
              </div>
              <div className={styles.row}>
                <TextInput
                  className={styles.input}
                  id="businessName"
                  key="businessName"
                  placeholder="Your business name"
                  labelText="Business Name*"
                  value={businessName}
                  onChange={(e) => {
                    setBusinessName(e.target.value)
                  }}
                  size="md"
                  invalid={!pristine && !businessName ? true : false}
                  invalidText="Business Name is required"
                />
              </div>
              <div className={styles.row}>
                <TextInput
                  className={styles.input}
                  id="businessURL"
                  key="businessURL"
                  placeholder="Your business URL"
                  labelText="Business URL"
                  value={businessURL}
                  onChange={(e) => {
                    setBusinessURL(e.target.value)
                    setValidBusinessURL(isValidURL(e.target.value))
                  }}
                  size="md"
                  invalid={
                    !pristine && businessURL && !validBusinessURL ? true : false
                  }
                  invalidText="Please enter valid URL address"
                />
              </div>
              <div className={styles.row}>
                <div className={styles.shortInput}>
                  <div className={styles.select}>
                    <MultiSelect
                      titleText="Industry"
                      useTitleInItem={false}
                      label={
                        selectedIndustries.length > 0
                          ? `${selectedIndustries.length} ${
                              selectedIndustries.length > 1
                                ? 'industries'
                                : 'industry'
                            } selected`
                          : 'Choose one or more options'
                      }
                      id="industry"
                      items={industries}
                      itemToString={(item) => (item ? item.text : '')}
                      onChange={(event) =>
                        setSelectedInustries(event.selectedItems)
                      }
                      initialSelectedItems={selectedIndustries}
                    />
                  </div>
                </div>
                <div className={styles.shortInput}>
                  <div className={styles.select}>
                    <MultiSelect
                      titleText="Country / Region"
                      useTitleInItem={false}
                      label={
                        selectedRegions.length > 0
                          ? `${selectedRegions.length} ${
                              selectedRegions.length > 1 ? 'regions' : 'region'
                            } selected`
                          : 'Choose one or more options'
                      }
                      id="region"
                      items={regions}
                      itemToString={(item) => (item ? item.text : '')}
                      onChange={(event) =>
                        setSelectedRegions(event.selectedItems)
                      }
                      initialSelectedItems={selectedRegions}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.shortInput}>
                  <div className={`${styles.multiSelect} ${styles.select}`}>
                    <MultiSelect
                      titleText="Default regions for classification"
                      useTitleInItem={false}
                      label={
                        selectedPreferedRegions.length > 0
                          ? `${selectedPreferedRegions.length} ${
                              selectedPreferedRegions.length > 1
                                ? 'regions'
                                : 'region'
                            } selected`
                          : 'Choose one or more options'
                      }
                      id="region"
                      initialSelectedItems={
                        selectedPreferedRegions ? selectedPreferedRegions : []
                      }
                      items={regions}
                      itemToString={(item) => (item ? item.text : '')}
                      onChange={(event) =>
                        setSelectedPreferedRegions(event.selectedItems)
                      }
                    />
                  </div>
                  <div className={styles.buttons}>
                    <div className={styles.button1}>
                      {existItem('US - United States') && (
                        <Button
                          className="costum-class"
                          size="sm"
                          kind="ghost"
                          onChange={() => returnSelectedPreferedRegions('US')}
                        >
                          US
                        </Button>
                      )}
                    </div>
                    <div className={styles.button2}>
                      {existItem('EU - European Union') && (
                        <Button
                          className="costum-class"
                          style={{
                            marginLeft:
                              selectedPreferedRegions.length > 1 ? 10 : 0
                          }}
                          size="sm"
                          kind="ghost"
                          onChange={() => returnSelectedPreferedRegions('EU')}
                        >
                          EU
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <p className={styles.requiredDisclaimer}>*Required fields</p>
            </div>
            <div className={styles.formActions}>
              {!loading ? (
                <Button
                  type="button"
                  className={styles.submit}
                  iconDescription="Search Icon"
                  onClick={handleSubmit}
                  size="field"
                  disabled={false}
                >
                  Submit
                </Button>
              ) : (
                <div className={styles.loading}>
                  <InlineLoading description={'Loading...'} status={'active'} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountInfo
