import styles from './UnderlineLink.module.scss'
import { Link } from 'carbon-components-react'

const UnderlineLink = ({ text, onClick }: any) => {
  return (
    <div className={styles.linkContainer}>
      <Link className={styles.link} onClick={onClick}>
        {text}
        <div className={styles.underline}></div>
      </Link>
    </div>
  )
}
export default UnderlineLink
