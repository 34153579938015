const getAuthData = (user: any) => {
  const metadata = user
    ? user['https://hs-classify.app/app_metadata'] || {}
    : {}
  if (!metadata.semantics3_key || !metadata.semantics3_secret) {
    metadata.semantics3_key = process.env.REACT_APP_API_KEY
    metadata.semantics3_secret = process.env.REACT_APP_API_SECRET
  }

  if (!metadata.account_type) {
    metadata.account_type = 'free'
  }
  return metadata
}

export { getAuthData }
