import { useContext, useEffect, useState } from 'react'
import {
  Link,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  DataTable,
  TableContainer,
  Loading
} from 'carbon-components-react'
import styles from './CSVFilePreview.module.scss'
import { previewCSV } from '../../../lib/apiClient'
import { setErrorNotifications } from '../../ErrorNotificationsProvider/action'
import { useStore } from '../../ErrorNotificationsProvider/ErrorNotificationsProvider'
import { getAuthData } from '../../../lib/util'
import DownloadTemplateModal from '../../DownloadTemplateModal/DownloadTemplateModal'
import { Warning16 } from '@carbon/icons-react'
import UnderlineLink from '../../UnderlineLink/UnderlineLink'
import { AccountContext } from '../../../App'

const CSVFilePreview = ({ file, fileName, setStep, user }: any) => {
  const { account } = useContext(AccountContext)
  const [loading, setLoading] = useState(false)
  const authData = getAuthData(user)
  const [prewiewCSV, setPrewiewCSV] = useState([])
  const [total, setTotal] = useState(0)
  const { dispatch } = useStore()
  const [openModal, setOpenModal] = useState(false)
  useEffect(() => {
    setLoading(true)
    previewCSV(file, account?.id || 0, authData)
      .then((data) => {
        if (data.error) {
          dispatch(
            setErrorNotifications({
              title: 'File upload error:',
              subtitle: data.error,
              open: true
            })
          )
          setStep(0)
          return
        }
        if (data?.result.length > 0) {
          setTotal(data.total)
          const formattedData = data.result.map((item: any, index: number) => ({
            id: `${index + 1}`,
            sku: item?.sku || 'n/a',
            productName: item?.productName || 'n/a',
            productDescription: item?.productDescription || 'n/a'
          }))
          setPrewiewCSV(formattedData)
        }
      })
      .then(() => setLoading(false))
      .catch((err) => {
        console.log(err)
        setLoading(false)
        dispatch(
          setErrorNotifications({
            title: 'File upload error:',
            subtitle: err.message,
            open: true
          })
        )
        setStep(0)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onClickForLink = () => {
    setStep(0)
  }
  const headers = [
    {
      key: 'sku',
      header: 'SKU'
    },
    {
      key: 'productName',
      header: 'Product Name'
    },
    {
      key: 'productDescription',
      header: 'Product Description'
    }
  ]
  const props = () => ({
    stickyHeader: true
  })
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  return (
    <div className={styles.root}>
      <h3 className={styles.title}>File Preview</h3>
      <p className={styles.previewText}>
        Previewing the first {prewiewCSV.length} of {total} items.
      </p>
      <div className={styles.table}>
        <p className={styles.fileName}>{fileName}</p>
        {loading && (
          <div className={styles.loading}>
            <Loading withOverlay={false} />
          </div>
        )}

        {!loading && (
          <>
            <DataTable rows={prewiewCSV} headers={headers} {...props()}>
              {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getTableContainerProps
              }: any) => (
                <TableContainer {...getTableContainerProps()}>
                  <Table {...getTableProps()} size="short">
                    <TableHead>
                      <TableRow>
                        {headers.map((header: any) => (
                          <TableHeader
                            key={header.key}
                            {...getHeaderProps({ header })}
                          >
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row: any) => (
                        <TableRow key={row.id} {...getRowProps({ row })}>
                          {row.cells.map((cell: any) => (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DataTable>
            <p className={styles.total}>Total of {total} items imported.</p>
          </>
        )}
      </div>
      <div className={styles.btnlink}>
        <Button
          onClick={() => setStep(2)}
          className={styles.btnFilePreviewCSV}
          iconDescription="Next step"
          size="field"
        >
          {' '}
          Next Step
        </Button>
        <div className={styles.underlineContainer}>
          <UnderlineLink text={'Back'} onClick={onClickForLink} />
        </div>
        <Link className={styles.link2} onClick={() => handleOpenModal()}>
          File Upload Help
          <Warning16 className={styles.icon} />
        </Link>
        {openModal && (
          <DownloadTemplateModal
            openModal={openModal}
            handleCloseModal={handleCloseModal}
          />
        )}
      </div>
    </div>
  )
}
export default CSVFilePreview
