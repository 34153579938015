import React from 'react'
import styles from './Payment.module.scss'

const Payment = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <p>
          For questions about billing and payment, please contact us at
          service@hscodes.io
        </p>
      </div>
    </div>
  )
}

export default Payment
