import React from 'react'
import styles from './Tier.module.scss'

const TierTag = ({ type }: { type: 'free' | 'pro' }) => {
  return (
    <div
      className={`${styles.tagContainer} ${
        type === 'free' ? styles.free : styles.courier
      }`}
    >
      <p>HSCODES {type === 'free' ? 'FREE' : 'COURIER'}</p>
    </div>
  )
}

export default TierTag
